<template>
  <v-form ref="form" v-model="valid" >
    <drawable-area-map v-model="params.area" :style="getViewHeightStyle(36)" />
  </v-form>
</template>

<script>
import DrawableAreaMap from "@/common/components/DrawableAreaMap";

export default {
  name: "MapAreaDialog",
  components: { DrawableAreaMap },
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      valid: true,
    }
  },
};
</script>
