<template>
  <div style="height: 250px; width: 100%; z-index: 0; position: relative;">
    <l-map
      ref="map"
      :options="mapOptions"
      :zoom="currentZoom"
      @update:zoom="zoomUpdate"
      @ready="setMap"
      style="height: 100%"
    >
      <l-tile-layer :url="url" :attribution="attribution" layer-type="base" />
      <l-feature-group ref="featureGroup" @ready="onFeatureGroupReady($event)">
        <l-polygon
          v-if="notEmptyArray(getArea)"
          :lat-lngs="getArea"
          color="#00c688"
        />
      </l-feature-group>
      <l-control-scale position="bottomleft" imperial metric></l-control-scale>
    </l-map>
    <unclickable right bottom />
  </div>
</template>

<script>
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LPolygon,
  LFeatureGroup,
  LControlScale,
} from "vue2-leaflet";
import Unclickable from "@/common/components/Unclickable";
import LDrawToolbar from "vue2-leaflet-draw-toolbar";

export default {
  name: "DrawableAreaMap",
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LFeatureGroup,
    LControlScale,
    LDrawToolbar,
    Unclickable,
  },
  model: {
    prop: "area",
    event: "input",
  },
  props: {
    area: Array,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      attribution: "&copy; OSM",
      currentZoom: this.getArea ? 13.5 : undefined,
      mapOptions: {
        attributionControl: false,
        zoomSnap: 0.5,
        minZoom: this.getArea ? 12 : undefined,
        maxZoom: this.getArea ? 20 : undefined,
      },
      center: null,
    };
  },
  computed: {
    getBounds() {
      if (this.notEmptyArray(this.getArea)) {
        var rectangle = L.rectangle(this.getArea[0]);
        var sw = rectangle.getBounds().getSouthWest();
        var ne = rectangle.getBounds().getNorthEast();
        var b = L.latLngBounds([
          [sw.lat, sw.lng],
          [ne.lat, ne.lng],
        ]);

        return b.pad(0.5);
      }
      return null;
    },

    // getBounds() {
    //   const lats = this.getArea[0].map((m) => this.coordsRound(m[0]));
    //   const lngs = this.getArea[0].map((m) => this.coordsRound(m[1]));
    //   // calc the min and max lng and lat
    //   var minlat = Math.min(...lats),
    //     maxlat = Math.max(...lats);
    //   var minlng = Math.min(...lngs),
    //     maxlng = Math.max(...lngs);

    //   // create a bounding rectangle that can be used in leaflet
    //   const bbox = [
    //     [minlat, minlng],
    //     [maxlat, maxlng],
    //   ];

    //   return bbox;
    // },
    getArea: {
      get() {
        return this.area;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },
  methods: {
    toggleEdit() {
      this.editable != this.editable;
      if (!this.editable) {
        this.getArea = this.$refs.polygon.mapObject._latlngs;
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    async setMap() {
      await this.$nextTick();

      if (!this.map) {
        this.map = this.$refs.map.mapObject;
        if (this.notEmptyArray(this.getArea)) {
          this.currentZoom = 14.5;
        }
      }

      if (this.notEmptyArray(this.getArea)) {
        this.map.fitBounds(this.getBounds, { padding: L.point(30, 30) });
      }

      this.map.on(L.Draw.Event.CREATED, (e) => {
        const { layer } = e;
        this.getArea = [layer._latlngs[0].map((m) => [m.lat, m.lng])];
      });
      this.map.on(L.Draw.Event.EDITED, (e) => {
        const { layers } = e;
        const slef = this;
        layers.eachLayer(function(layer) {
          slef.getArea = [layer._latlngs[0].map((m) => [m.lat, m.lng])];
        });
      });
      this.map.on(L.Draw.Event.DELETED, (e) => {
        this.getArea = null;
      });

      setTimeout(() => {
        this.map.invalidateSize();
      }, 200);
    },
    async onFeatureGroupReady(val) {
      await this.$nextTick();
      const drawControl = new L.Control.Draw(this.getDrawConfiguration(val));
      this.map.addControl(drawControl);
    },
    getDrawConfiguration(editableLayers) {
      return {
        position: "topright",
        draw: {
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: "#e1e100", // Color the shape will turn when intersects
              message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
            },
            shapeOptions: {
              color: "#00c688",
            },
          },
          // disable toolbar item by setting it to false
          polyline: false,
          circle: false,
          circlemarker: false,
          rectangle: false,
          marker: false,
        },
        edit: {
          featureGroup: editableLayers,
          remove: true,
        },
      };
    },
  },
};
</script>

<style>
.pin {
  position: absolute;
  border-radius: 50% 50% 50% 0;
  border: 2px solid #fff;
  width: 32px;
  height: 32px;
  transform: rotate(-45deg);
  margin-top: -16px;
}

.pin .icon-position {
  transform: rotate(45deg);
  margin-top: 1px;
  margin-left: 3px;
}
</style>
