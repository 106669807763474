<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="point.latitude"
        :rules="rule"
        type="number"
        :label="$t('general.latitude')"
        required
        dense
      />
    </v-col>
    <v-col>
      <v-text-field
        v-model="point.longitude"
        :rules="rule"
        type="number"
        :label="$t('general.longitude')"
        required
        dense
        append-outer-icon="mdi-map-marker"
        @click:append-outer="openLocationDialog"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import MapLocationDialog from "../../views/general/MapLocationDialog";

export default {
  name: "MapLocationPicker",
  // eslint-disable-next-line vue/no-unused-components
  components: { MapLocationDialog },
  model: {
    prop: "point",
    event: "input",
  },
  props: {
    point: Object,
    rule: Array,
  },
  computed: {
    getPoint: {
      get() {
        return this.point;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  methods: {
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    openLocationDialog() {
      const dialog = {
        translate: true,
        title: "Store Location",
        // text: "Select your store location",
        component: MapLocationDialog,
        width: "400px",
        noGutter: true,
        // fullscreen: true,
        params: {
          point: this.getPoint,
        },
        actions: [
          {
            text: "Okay",
            click: ({ point }) => {
              console.log(point);
              this.getPoint = point
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: "Cancel",
            click: () => {
              this.closeDialog();
            },
            color: "primary",
          },
        ],
      };

      this.showDialog(dialog);
    },
  },
};
</script>
